import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import Layout from "../containers/Home/Layout";
import Blogs from "../containers/Home/Blogs";
import Titlebar from "common/components/Titlebar";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

const BlogsPage = () => {

  const seoData = {
    title: "Radiance Hair Studio Blog | Expert Hair Care Tips & Trends",
    description: "Explore the Radiance Hair Studio Blog for expert hair care tips, styling tutorials, and the latest trends in hair fashion. Stay updated with professional advice",
    keywords: ["Radiance Hair Studio Blog "],
  };

    const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Define your WordPress API endpoint
    const apiUrl = 'https://radiancehairstudio.com/blog/wp-json/wp/v2/posts';

    axios.get(apiUrl)
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching WordPress posts:', error);
      });
  }, []);

  const postsAsObjects = [];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} />
        <Layout>
            <Titlebar title="Blogs" />
            
            <div>
                    {posts.map(post => {

                      const postObject = {
                        id: post.id,
                        image: post.featured_image_url,
                        title: post.title.rendered,
                        desc: post.excerpt.rendered,
                        date: post.date,
                        author: "Radiance",
                      };

                      postsAsObjects.push(postObject);
                      
                    
                  })}
            </div>
            <div className='container'>
              <Blogs slidesView="3" posts={ postsAsObjects } />
            </div>
          </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default BlogsPage;
