import React, { useState, useEffect } from "react";
import SectionWrapper, { Container } from "./blogs.style";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Blogs = (props) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await axios.get(
          "https://www.radiancehairstudio.com/blog/wp-json/wp/v2/posts"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    }

    fetchPosts();
  }, []);

  const swiperBreakpoints = {
    // When viewport width is less than or equal to 767px (typical mobile size)
    767: {
      slidesPerView: 2, // Display 2 slides on mobile
      spaceBetween: 10, // Adjust as needed
    },
    // When viewport width is greater than 767px (larger screens)
    768: {
      slidesPerView: props.slidesView ? props.slidesView : 2, // Display 1 slide on larger screens
      spaceBetween: 20, // Adjust as needed
    },
  };

  const allblogs = [
    {
      id: "1",
      title: "Delhi Hair Wigs Price Guide: Budget-Friendly Solutions",
      image: "/home/Delhi-Hair-Wigs-Price-Guide.webp",
      desc: "Hair has always held a special place in the realm of beauty and self-expression. It plays a significant role in defining our appearance...",
      date: "10 August 2023",
      author: "Radiance",
    },
    {
      id: "2",
      title: "Top Benefits Of Getting Hair Extension in Delhi",
      image: "/home/Hair-Extension-In-Delhi.png",
      desc: "Are you thinking of getting hair extensions? With the recent popularity of hair extensions, many have wondered if they should...",
      date: "08 August 2023",
      author: "Radiance",
    },
    {
      id: "3",
      title: "How To Choose The Best Hair Wig For Men In Delhi?",
      image: "/home/hair-wig-for-men-in-Delhi.webp",
      desc: "Are you planning to buy a hair wig for men in Delhi? Wait! Finding an ideal hair wig for men in Delhi isn’t as easy...",
      date: "31 July 2023",
      author: "Radiance",
    },
  ];

  const postsData = props.posts ? props.posts : allblogs;

  function stripHtmlTagsAndEntities(htmlString, wordLimit = 20) {
    // Create a temporary element to parse the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Get the text content without HTML tags
    const textContent = tempElement.textContent || tempElement.innerText;

    // Replace unnecessary entities like &hellip; with their respective characters
    const sanitizedContent = textContent.replace(/&hellip;/g, '...');

    // Limit the content to the first 20 words
    const words = sanitizedContent.split(/\s+/);
    const limitedContent = words.slice(0, wordLimit).join(' ');

    return limitedContent;
}

  return (
    <SectionWrapper id="watchVideos">
      <div
        className="flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <h3 className="title results_title">Featured Blogs</h3>
        <div className="flex">
          {/* Custom navigation buttons */}
          <div className="swiper-button-prev8">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div className="swiper-button-next8">
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>

      <Swiper
        spaceBetween={0} // Adjust as needed
        slidesPerView={1} // Display 4 slides per view
        // loop={true}
        navigation={{
          prevEl: ".swiper-button-prev8",
          nextEl: ".swiper-button-next8",
        }}
        // autoplay={{ delay: 3000 }}
        breakpoints={swiperBreakpoints}
        // pagination={{ clickable: true }}
        style={{ marginTop: "15px" }}
      >
        {posts.map((post, index) => (
          <SwiperSlide key={index}>
            <div className="blogCard">
              <img
                loading="lazy"
                width="600"
                height="400"
                src={post.featured_image_url}
                alt={post.title.rendered}
              />
              <h3>{post.title.rendered.replace(/&#8217;s/g, ' &')}</h3>
              <p className="excerpt">{stripHtmlTagsAndEntities(post.excerpt.rendered, 23)}</p>
              <a target="_blank" href={post.link}>Read More</a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </SectionWrapper>
  );
};

export default Blogs;
